import Vue from 'vue';

// SASS/CSS
import '../../css/public.scss';

// images
import '../../images/icons.svg';

// disable the warning about dev/prod
Vue.config.productionTip = false;

new Vue({
    el: '#app',

    data () {
        return {
            show: false,
            subnavShow: false,
        };
    },

    mounted () {
        this.$nextTick(() => {
            window.addEventListener('resize', () => { this.showMobileMenu = false });
        });
    },

    methods: {
        toggle (e) {
            this.show = !this.show
            e.preventDefault();
        },

        togglesubnav (e) {
            this.subnavShow = !this.subnavShow
            e.preventDefault();
        },

        windowResize () {
            this.show = false;
            this.subnavShow = false;
        },
    },
});
